<template>
    <div>   
    <v-divider></v-divider>
    <mbs-page-tab 
        :items="PageTaps"/> 
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-divider></v-divider>
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab v-if="Tab_PermissionTypes"
                            color="secondary"
                            :items="Tab_PermissionTypes"
                            v-model="input_tab_permission_type"
                            /> 

                        <mbs-item-manager v-model="input_table"
                            :add_vertical_lines="true"
                            :row_click="true"
                            :table_tabs="tableTabs"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="DATA.FIELDS.permissions"
                            :header_items="HEADER_ITEMS" 
                            :data_items="FILTERED_PERMISSIONS" 
                            :is_joined="true" 
                            :path_item="DATA.PATHS.permissions"  
                            :select_items="DataSource"  
                            :table_actions="TableActions" 
                            @ON_ITEM_SELECTED="selected_items=$event" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                </v-col>  

            </v-layout>
        </v-container>  

        <!-- //DIALOGS -->
        <div> 
            <!-- add permission -->
            <div v-if="dialog_add_permission">
                <v-dialog v-model="dialog_add_permission" persistent max-width="800px"> 
                    <v-card>
                        <v-card-title class="primary white--text" dark>
                            <span class="headline">ADD PERMISSION</span>
                            <v-spacer></v-spacer> 
                            <v-btn icon dark @click="dialog_add_permission=false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text> 
                            <v-container grid-list-lg>
                                <br>
                                <form id="updateUserForm" @submit.prevent="SUBMIT_PERMISSION()">
                                    <mbs-input-fields v-if="true"
                                        v-model="input"
                                        :field_items="DATA.FIELDS.permissions"
                                        :select_items="DataSource"
                                        /> 
                                    <v-card-actions>
                                         <v-flex >
                                             <v-btn width="100%" color="primary" :loading="inputLoading" dark="" type="submit">Add Permission</v-btn> 
                                         </v-flex>
                                        <v-btn color="secondary" class="ml-1 px-9" @click="dialog_add_permission=false">Cancel</v-btn>
                                    </v-card-actions>
                                </form> 
                            </v-container>
                            <small>Click Update button to save changes</small>
                        </v-card-text> 
                    </v-card>
                </v-dialog>  
            </div>

            <!-- edit -->
            <div v-if="dialog_edit_permission">
                <v-dialog v-model="dialog_edit_permission" persistent max-width="800px"> 
                    <v-card>
                        <v-card-title class="primary white--text" dark>
                            <span class="headline">EDIT USER ACCOUNT</span>
                            <v-spacer></v-spacer> 
                            <v-btn icon dark @click="dialog_edit_permission=false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text> 
                            <v-container grid-list-lg>
                                <br>
                                <form id="updateUserForm" @submit.prevent="UPDATE_USER()">
                                    <mbs-input-fields v-if="true"
                                        v-model="input"
                                        :field_items="DATA.FIELDS.permissions"
                                        :update_item="true"
                                        :select_items="DataSource"
                                        /> 
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text="" @click="dialog_edit_permission=false">CANCEL</v-btn>
                                    <v-btn color="primary" :loading="input.loading" dark="" type="submit">Update User</v-btn>
                                    </v-card-actions>
                                </form> 
                            </v-container>
                            <small>Click Update button to save changes</small>
                        </v-card-text> 
                    </v-card>
                </v-dialog>  
            </div>
        </div> 

        <div v-if="ADDING_ITEM">
            <mbs-item-manager 
                :show="false" 
                :add_item_action="ADDING_ITEM.add_item_action"
                :edit_item_action="ADDING_ITEM.edit_item_action"
                :name_items="ADDING_ITEM.names"
                :name_item="ADDING_ITEM.name"
                :value_items="ADDING_ITEM.values"
                :value_item="ADDING_ITEM.value"
                :field_items="ADDING_ITEM.fields"   
                :data_items="JOIN_PERMISSIONS"  
                :is_joined="true" 
                :path_item="DATA.PATHS.permissions"  
                :select_items="DataSource"  
            /> 
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    let PAGE_NAME = DATA.ITEMS.PERMISSIONS.values
    let ACTION_TIME = 100
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.PERMISSIONS.names,
                NAME_ITEM:"_"+DATA.ITEMS.PERMISSIONS.name,
                VALUE_ITEMS:DATA.ITEMS.PERMISSIONS.values,
                VALUE_ITEM:DATA.ITEMS.PERMISSIONS.value,  
                DATA:DATA, 
                tableTabs:[
                    {name:"All Permissions",show_if:null},
                    {name:"Company",show_if:(this_,item)=>item.access_type == "company"},
                    {name:"Public",show_if:(this_,item)=>item.access_type=="public"},
                    {name:"User ",show_if:(this_,item)=>item.access_type=="public user"}, 
                ],
                table_actions:[
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},   
                    {type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Permission"},  
                ],
                input_table:{show_select:false},
                input_tab_permission_type:{},
                selected_items:null,
                ADDING_ITEM:null,
                dialog_edit_permission:false,
                dialog_add_permission:false,
                input:{},
    
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try { 
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar',  
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            app_links(){
                let paths = this.$router.options.routes
                let list = [...paths]
                list.sort(this.MBS.actions.dynamicSort("path",true))    
                return list
            },
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  
            ActionPermissions(){ 
                let action_permissions = []
                action_permissions.push(DATA.ITEMS.ITEMS)
                action_permissions.push(DATA.ITEMS.ITEM_ORDERS) 
                action_permissions.push(DATA.ITEMS.SALES) 
                return action_permissions
            }, 
            PermissionTypes(){  
                return [
                    {name:'page'},
                    {name:'action'},
                ]
            }, 
            SystemDataItems(){  
                let items = DATA.ITEMS?Object.entries(DATA.ITEMS).map(([key,value])=>({
                    ...value,
                    key:key
                })):null
                console.log(items,'items..............');
                return items
            }, 
            UnnamedSystemDataItems(){ 
                let permissions = this.PERMISSIONS_DATA
                let path = this.SystemDataItems
                if(!path){return null} 
                let filtered = path.filter(element=>{
                    let findIndex = name=>{
                        if(!name || !permissions){return path}
                        return permissions.findIndex(p=>p.name == name)
                    } 
                    let index = findIndex(element.name)
                    return index == -1 ? true : false
                }) 
                return filtered
            },   
            PageTaps(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("users&permissions",2) 
                return thisLinks
            },
            
            TableActions(){
                let selected_items = this.selected_items
                let input_table = this.input_table
                let show_select = input_table?input_table.show_select:false
                let main_action = [
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},    
                    {type:"btn",action:"add_action_item",color:"secondary", outlined:false,  text:"Add Permission"},    
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ]

                 
                let selected_items_action = [
                    {type:"action",btn:true,icon:"mdi-close",action:"cancel_selected_items",color:"secondary", outlined:false,  text:"Cancel Selected Items"},  
                ]
                if(this.MBS.actions.SIZE(selected_items)){
                    selected_items_action.push(
                        {type:"action",btn:true,icon:'mdi-delete',action:"delete_selected_items",color:"secondary", outlined:false,  text:"Delete Selected Items"}
                    )
                }
                return  show_select?selected_items_action:main_action
            },
            Tab_PermissionTypes(){  
                let types = this.PermissionTypes
                if(!types){return null}
                let pages = []
                types.forEach(element => {
                    pages.push({
                        ...element,
                        name:element.name+" PERMISSIONS"
                    })
                });
                return pages
            },
            CurrentTab_PermissionTypes(){
                let input = this.input_tab_permission_type 
                return   input
            },
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    // {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"",value:'icon_image',color:"primary",tile:true,size:40,show:true},
                    {id:0,name:"code",align:"center",show:false},     
                    {id:0,name:"key",align:"left",show:false},     
                    {id:0,name:"Path",value:"path_link",show:true},     
                    {id:0,name:"Permission type",value:"permission_type",show:true},     
                    {id:0,name:"Access type",value:"access_type",show:true},     
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"create",value:'created_at',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Select Item",action:"select_item",icon:"mdi-checkbox-marked"},
                        {name:"Edit Permission",action:"edit_action_item",icon:"mdi-pencil"},
                        {name:"Delete Permission",action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            }, 
            PERMISSIONS_DATA(){ 
                let permissions = this.$store.getters.getPermissions
                console.log(permissions,'permissions..........');
                if(!permissions){return null} 
                let list = [...permissions]
                list.sort(this.MBS.actions.dynamicSort("path_link",false))  
                return list
            }, 
            JOIN_PERMISSIONS(){ 
                let permissions = this.PERMISSIONS_DATA
                let join_permission = this.MBS.actions.JOIN_PERMISSIONS_PATHS(permissions) 
                if(!join_permission){return null} 
                let list = [...join_permission]
                list.sort(this.MBS.actions.dynamicSort("path_link",true))  
                return list
            }, 
            FILTERED_PERMISSIONS(){ 
                let permissions = this.JOIN_PERMISSIONS
                let tap = this.CurrentTab_PermissionTypes
                if(!permissions){return null}
                let filtered = permissions.filter(perm=>{
                    return tap?perm.permission_type == 'action':perm.permission_type == 'page'
                })
                return filtered
            }, 
            UNNAMED_PATH(){ 
                let permissions = this.PERMISSIONS_DATA
                let path = this.app_links
                if(!path){return null} 
                let filtered = path.filter(element=>{
                    let findIndex = name=>{
                        if(!name || !permissions){return path}
                        return permissions.findIndex(p=>p.name == name)
                    } 
                    let index = findIndex(element.name)
                    return index == -1 ? true : false
                }) 
                return filtered
            },   
            LINKS(){
                let links = this.MBS.actions.LINKS  
                return links 
            },  
            DataSource(){ 
                let SystemDataItems = this.SystemDataItems
                let UnnamedSystemDataItems = this.UnnamedSystemDataItems
                let item = this.PermissionTypes
                let item2 = [
                    {name:'public'},
                    {name:'public user'},
                    {name:'private'},
                    {name:'company'},
                ]
                // let links = this.LINKS
                // console.log(this.app_links,'Pages');
                // console.log(this.UNNAMED_PATH,'UNNAMED_PATH..');
                let ActionPermissions = this.ActionPermissions
                console.log(ActionPermissions,'ActionPermissions..... ..');
                return {
                    ActionPermissions:ActionPermissions,
                    permission_type:item,
                    access_type:item2,
                    unnamed_app_links:this.UNNAMED_PATH,
                    app_links:this.app_links ,
                    SystemDataItems:SystemDataItems,
                    UnnamedSystemDataItems:UnnamedSystemDataItems,
                }
            },   
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                    if (action == "add_action_item") {
                        this.ADD_PERMISSION()
                    }else if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Permissions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    }else if (action == "manage_customer_groups") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/office/people/customers/groups",true))
                    }else if (action == "stock_settings") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/settings",true))
                    }else if (action == "cancel_selected") {
                        this.CANCEL_SELECTED_ITEMS(item)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ITEM_ACTION(action,item,option){
                try {     
                    console.log(action,'***************');
                    if (action == "on_row_clicked") {
                         
                    }else if (action == "select_item") {
                         
                    }else if (action == "edit_item") {
                         
                    }else if (action == "delete_item") { 
                    }else if (action == "edit_action_item") {
                         this.EDIT_PERMISSION(item)
                    }else if (action == "add_item_kit") {
                         this.ITEM_ADD_KITS(item)
                    }else if (action == "edit_item_price") {
                         this.EDIT_ITEM_PRICE(item)
                    }else if (action == "edit_items_department_and_category") {
                         this.EDIT_ITEM_DEPARTMENT_AND_CATEGORY(item)
                    }else{
                        console.log(action,item,option,'action,item,option..........');
                        this.MBS.actions.dialog(null,true,false,"Coming Soon!..","Current not working. Try again later.",null,"OK") 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADD_PERMISSION(){
                try {    
                    this.input = {}
                    this.inputLoading = false
                    this.dialog_add_permission = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_PERMISSION',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            SUBMIT_PERMISSION(){
                try {    
                    let p = true
                    if (!p) {
                        let mbs_text = this.MBS.text.edit_item_permission_denied(this.NAME_ITEMS)
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:mbs_text.title,
                           text:mbs_text.text,
                           btnYes:mbs_text.btnYes 
                       }) 
                    } else { 
                        let input = this.input
                        let permission = input?input.permission:null
                        let access_type = input?input.access_type:null  
                        let permission_type = input?input.permission_type:null
                        let name = permission?permission.name:null 
                        let path = permission?permission.path:null  
                        if (permission_type=="action") {
                            name = permission?.names
                            path = permission?.values
                        }
                        let permission_details = {
                            name:name,
                            path_link:path, 
                            permission_type:permission_type, 
                            access_type:access_type, 
                        }
                        console.log(input,'......');
                        console.log(permission_details,'permission_details......');
                        let mbs_text = this.MBS.text.item_action(this.NAME_ITEM,"Add")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=ADD-PERMISSION",
                                data:permission_details
                            }
                        })  
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_PERMISSION',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            EDIT_PERMISSION(item){
                try {    
                    console.log(item);
                    let p = true
                    if (!p) {
                        let mbs_text = this.MBS.text.edit_item_permission_denied(this.NAME_ITEMS)
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:mbs_text.title,
                           text:mbs_text.text,
                           btnYes:mbs_text.btnYes 
                       }) 
                    } else {  
                        this.inputCode = null
                        this.input = {}   
                        this.input.key = item.key  
                        this.input.path_link = item.path_link  
                        this.input.name = item.name  
                        this.input.code = item.code  
                        this.input.permission_type = item.permission_type  
                        this.input.access_type = item.access_type  
                        this.dialog_edit_permission= true  
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_PERMISSION',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async RELOAD_ITEMS(){
                try { 
                    let res = await this.$store.dispatch('fi_get_user_items',{
                        name:DATA.ITEMS.PERMISSIONS.values,
                        on:true, 
                        cash:true, 
                        get:false,  
                    }) 
                    console.log(res,'res.......');
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.PERMISSIONS.values,"Refresh")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    })
                } catch (error) {
                    console.log(error,'error.......');
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.PERMISSIONS.values,"Refresh")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    }) 
                }

            },
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-PERMISSION') {
                            if (action.YES) { 
                                setTimeout(() => {
                                    this.input.loading = true 
                                    this.$store.dispatch("fi_add",{
                                        action:this.VALUE_ITEM,
                                        path:DATA.PATHS.permissions,
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        console.log(res,'res....');
                                        this.inputLoading = false
                                        this.dialog_add_permission = false
                                        this.MBS.actions.progressDialog()
                                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.PERMISSIONS.values,"Adding")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'error...');
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog()
                                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.PERMISSIONS.values,"Adding")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, ACTION_TIME);  
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
        },
        beforeDestroy(){
            try { 
                this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>